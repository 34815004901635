
.oh-table {
    width: 100%;
    height: 100%;
    text-align: center;
    word-break: normal;
    word-wrap: normal;
}
.oh-table th {
    padding: 0;
    margin: 0;
}

.oh-table, .oh-table td {
    border-collapse: collapse;
    background-clip: padding-box;
    border-right: 1px solid #ccc;
}

.oh-timecell {
    background-color: white;
    position: relative;
    box-sizing: border-box;
}

.oh-table th {
    font-weight: bold;
    font-size: medium;
    background-color: #ddd;
}

.oh-table td {
    vertical-align: top;
}

.oh-timecell:hover {
    background-color: var(--catch-detail-color) !important;
}


.oh-timecell-selected {
    background-color: var(--catch-detail-color);
}


.oh-timecell-half {
    border-bottom: 1px solid #ddd;
    background-color: var(--subtle-detail-color);
}

.oh-timecell-half.oh-timecell-selected {
    background-color: var(--catch-detail-color);
}

.oh-table tr {
    background: #ddd;
}

.oh-left-col {
    border-bottom: 1px solid #aaa;
    margin: 0;
    margin-left: 10px;
    width: 0.5em;
    font-size: large;
    padding: 0;
    padding-right: 0.2em;
    box-sizing: border-box;
}

.oh-timecell-0 {
    border-left: 10px solid rgba(0, 0, 0, 0);
}

.oh-timecell-6 {
    border-right: 10px solid rgba(0, 0, 0, 0);
}

.oh-timecol-selected {
    border-right: var(--catch-detail-color);
}

.oh-timecol-selected > span {
    background-color: var(--catch-detail-color);
    color: var(--background-color);
    width: 100%;
    display: block;
}

.oh-timecol-selected-round-left > span {
    border-top-left-radius: 5em;
    border-bottom-left-radius: 5em;
}

.oh-timecol-selected-round-right > span {
    border-top-right-radius: 5em;
    border-bottom-right-radius: 5em;
}

.oh-timerow-selected .oh-timecell-0 {
    border-left: 10px solid var(--catch-detail-color) !important;
}

.oh-timerow-selected .oh-timecell-6 {
    border-right: 10px solid var(--catch-detail-color) !important;
}

.oh-timerange {
    color: var(--catch-detail-color-contrast);
    border-radius: 0.5em;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: calc(5% - 1px);
    width: 90%;
    background: var(--catch-detail-color);
    z-index: 1;
    box-sizing: border-box;
    border: 2px solid var(--catch-detail-color);
    overflow: unset;
}

/**** Opening hours visualization table ****/

.ohviz-table {
    word-break: normal;
    word-wrap: normal;
}

.ohviz-range {
    display: block;
    background: #99e7ff;
    position: absolute;
    left: 0;
    top: 5%;
    height: 85%;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    text-align: center;
    font-size: smaller;
}

.ohviz-today .ohviz-range {
    border: 1.5px solid black;
}

.ohviz-day-off {
    display: block;
    background: repeating-linear-gradient(
            45deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0) 10px,
            rgba(102, 207, 255, 0.5) 10px,
            rgba(102, 207, 255, 0.5) 20px
    );
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    color: black;
    font-weight: bold;
    text-align: center;
    border-radius: 1em;
}

.ohviz-today .ohviz-day-off {
    display: block;
    background: repeating-linear-gradient(
            45deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0) 10px,
            rgb(153, 231, 255) 10px,
            #99e7ff 20px
    );
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    color: black;
    font-weight: bold;
    text-align: center;
    border-radius: 1em;
}


.ohviz-line {
    position: absolute;
    top: 0;
    margin: 0;
    height: 100%;
    border-left: 1px solid #999;
    box-sizing: border-box
}
